import downscale from 'downscale';
import axios from 'axios';
import { IP_REGISTRY_API } from '@/config/config';

export const buildFileUrl = ({id, bucket}) => {
	if(!id || !bucket) {
		throw new Error('bucket and id are require as parameters');
	}

	return `https://storage.googleapis.com/${bucket}/${id}`;
};

export const transformTourDataForViewer = (data) => {
	return {
		images: [{link: data.coverPicUrl, name: 'portrait'}],
		panos: data.panoramas.map(pano => {
			return {id: pano.id, link: pano.fileUrl, name: pano.name, links: pano.links, infoElements: pano.infoElements}
		}),
		firstPhotoSphereId: data.startPanorama && data.startPanorama.id,
		POV: data.startPanorama && data.startPanorama.POV
	}
}

export const getImgSize = (file) => {
	return new Promise((resolve) => {
		const img = document.createElement('IMG');
		img.onload = function() {
			resolve({width: this.width, height: this.height});
			URL.revokeObjectURL(img.src);
		};

		img.src = URL.createObjectURL(file);
	});
}

const MAX_PANO_WIDTH = 4000;
export const downScaleImg = async (file) => {
	const {width} = await getImgSize(file);
	
	// dowscale if image is too big
	if(width >= MAX_PANO_WIDTH) {
		const domString = await downscale(file, MAX_PANO_WIDTH, 0, {returnBlob: 1});

		file = new File([domString], file.name);
	}

	return file;
};

export const getUserCountry = async () => {
  let country;

  try {
    const response = await axios.get(`https://api.ipregistry.co/?key=${IP_REGISTRY_API}&fields=location.country.name`);
    country = response.data.location.country.name;
  } catch(e) {
    country = '';
  }

  return country;
}
